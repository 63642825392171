<template>
  <span>
    <span v-if="method.type === paymentMethodPaypal">
      {{ method.type + ' - ' + method.email }}
    </span>
    <span v-else-if="method.type === paymentMethodCard">
      {{
        method.type + ' - ' + method.card.type + ', ' + $t('payment.nr') + ' '
      }}
      {{ method.card.number | hide }}
    </span>
    <span v-else-if="method.type === paymentMethodBank">
      {{
        method.type + ' - ' + method.bank.name + ', ' + $t('payment.nr') + ' '
      }}
      {{ method.bank.accountNumber | hide }}
    </span>
  </span>
</template>

<script>
import {
  PAYMENT_METHOD_PAYPAL,
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_BANK
} from '../../../constants/app'

export default {
  filters: {
    hide: number => {
      const numberLength = number.length
      const last4digits = number.substr(numberLength - 4, numberLength)
      return '*'.repeat(numberLength - 4) + last4digits
    }
  },
  inheritAttrs: false,
  props: {
    method: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      paymentMethodPaypal: PAYMENT_METHOD_PAYPAL,
      paymentMethodCard: PAYMENT_METHOD_CARD,
      paymentMethodBank: PAYMENT_METHOD_BANK
    }
  }
}
</script>
